import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ImageIcon from "@material-ui/icons/Image";
import CloseIcon from "@material-ui/icons/Close";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

import "./index.sass";

import Grid from "../../Molecules/Grid";
import Text from "../../Atoms/Text";
import Button from "../../Atoms/Button";
import Box from "../../Molecules/Box";
import CartItem from "../../Molecules/CartItem";
import CartPrice from "../../Molecules/CartPrice";
import AllDigitalPackage from "../../Molecules/AllDigitalPackage";
import BuyModal from "../BuyModal";
import Timer from "../../Atoms/Timer";
import Tooltip from "../../Atoms/ToolTip";

import {
	fetchProducts,
	fetchOrder,
	addAllDigital,
	removeAllDigital,
	removeMediaFromOrder,
	changeSelectedOrderMedia,
	getPayment,
	finishOrder,
	clearPayment,
} from "../../../store/actions";
import { Order, Analytics } from "../../../shared";

class Cart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileCartIsOpen: false,
			buyModalOpened: false,
			hasBeenVisible: false,
			isAdding: false,
		};

		this.openMobileCart = this.openMobileCart.bind(this);
		this.closeMobileCart = this.closeMobileCart.bind(this);
		this.openBuyModal = this.openBuyModal.bind(this);
	}

	componentDidMount() {
		const {
			products,
			order,
			cardCode,
			fetchProducts,
			fetchOrder,
			organization: orgState,
		} = this.props;
		const { organization, loading: organizationLoading } = orgState;
		const { id: orgId } = organization;
		const { loading: productsLoading } = products;

		if (!productsLoading && !organizationLoading) {
			fetchProducts(orgId);
		}

		const { loading: orderLoading } = order;

		if (!orderLoading && !organizationLoading) {
			fetchOrder(cardCode, orgId);
		}
	}

	componentDidUpdate() {
		const { payment, order: orderState, clearPayment } = this.props;
		const { loading: loadingPayment, status } = payment;
		const { order, loading: loadingOrder } = orderState;
		const { completed } = order;
		const { buyModalOpened } = this.state;

		if (!loadingPayment && !loadingOrder && !completed && status === 'PAID') {
			this.finishOrder();
			clearPayment();

		} else if (!loadingPayment && !loadingOrder && completed && !buyModalOpened) {
			this.openBuyModal();
		}
	}

	finishOrder = () => {
		const { order: orderState, finishOrder } = this.props;
		const { order = {} } = orderState;
		const { id: orderId = '' } = order;

		finishOrder(orderId);
	}

	onGetPayment = () => {
		const { order: orderState, getPayment } = this.props;
		const { order } = orderState;
		const { id: orderId } = order;
		getPayment(orderId);
	};

	openMobileCart() {
		this.setState({
			mobileCartIsOpen: true,
		});
	}

	closeMobileCart() {
		this.setState({
			mobileCartIsOpen: false,
		});
	}

	openBuyModal() {
		const { organization: orgState } = this.props;
		const { organization } = orgState;
		const { name: orgName } = organization;

		this.setState({
			buyModalOpened: true,
		});
		Analytics.sendEvent("open_finish_modal", "buying", orgName);
	}

	handleAllDigitalChange = () => {
		const {
			order: orderState,
			organization: orgState,
			products,
			addAllDigital,
			removeAllDigital,
		} = this.props;
		const { order } = orderState;
		const { id: orderId } = order;
		const { all_digital } = products;
		const { id: productId } = all_digital;
		const { organization } = orgState;
		const { name: orgName } = organization;
		let eventName;

		if (!Order.hasAllDigital(order)) {
			addAllDigital(orderId, productId);
			eventName = "add_all_digital_product";
		} else {
			const { id: orderItemId } = Order.getOrderItemByProductId(
				order,
				productId
			);
			removeAllDigital(orderId, orderItemId);
			eventName = "remove_all_digital_product";
		}

		Analytics.sendEvent(eventName, "buying", orgName);
	};

	removePhoto = (associatedItemId) => {
		const {
			order: orderState,
			organization: orgState,
			removeMediaFromOrder,
			changeSelectedOrderMedia,
		} = this.props;
		const { order } = orderState;
		const { id: orderId } = order;
		const { id: orderItemId } = Order.getOrderItemByAssociatedItemId(
			order,
			associatedItemId
		);
		const { organization } = orgState;
		const { name: orgName } = organization;

		removeMediaFromOrder(orderId, orderItemId, associatedItemId);
		changeSelectedOrderMedia(null);
		Analytics.sendEvent("remove_photo_from_cart", "buying", orgName);
	};

	onCloseBuyModal = () => {
		const {
			order: orderState,
			cardCode,
			fetchOrder,
			changeSelectedOrderMedia,
			organization: orgState,
		} = this.props;
		const { order, loading } = orderState;
		const { completed } = order;
		const { organization } = orgState;
		const { id: orgId } = organization;

		if (completed && !loading) {
			fetchOrder(cardCode, orgId);
		}

		changeSelectedOrderMedia(null);
		this.setState({ buyModalOpened: false, mobileCartIsOpen: false });
	};

	handleTootilpClose = () => {
		const { hasBeenVisible } = this.state;
		if (!hasBeenVisible) {
			this.setState({ hasBeenVisible: true });
		}
	};

	showTooltip = () => {
		const { order: orderState, products } = this.props;
		const { all_digital } = products;
		const { order } = orderState;
		const notSoldTotalPrice = order.total;
		const getAllDigitalPrice = () => (all_digital ? all_digital.price : null);

		return getAllDigitalPrice() < notSoldTotalPrice;
	};

	render() {
		const {
			intl,
			order: orderState,
			products,
			media,
			changeSelectedOrderMedia,
			payment,
		} = this.props;
		const { pixText, loading, method } = payment;
		const { mobileCartIsOpen } = this.state;
		const { formatMessage } = intl;
		const { all_digital } = products;
		const { order, selectedMediaId, isAddingAllDigital, mediaBeingRemoved } = orderState;
		const { items = [], payments = [] } = order;
		const { notSoldMedias = [] } = media;
		const hasPixPayment = payments.find((p) => p.modality = 'pix')
		const orderMedias = Order.getDinamicOrderItemMedias(order);
		const totalOrderPhotos = Order.getTotalOrderPhotos(
			order,
			notSoldMedias ? notSoldMedias.length : 0
		);
		const allDigitalIsSelected = Order.hasAllDigital(order);
		const messages = defineMessages({
			choosePics: {
				id: "cart.choosePics",
				defaultMessage: "Escolha fotos favoritas",
			},
			checkoutButton: {
				id: "cart.checkoutButton",
				defaultMessage: "Finalizar pedido",
			},
			checkingPayment: {
				id: "pixForm.checkingPayment",
				defaultMessage: "Verificando pagamento",
			},
			tooltipCartText:{
				id: "cart.tooltip",
				defaultMessage: "Oferta exclusiva! Ao adquirir todas as mídias, você pagará menos que na aquisição individual. Confira nossa tabela de valores!",
			},
		});

		return (
			<div className="Cart">
				<div
					className={`Cart__mobileCart ${
						mobileCartIsOpen ? "Cart__mobileCart--opened" : ""
					}`}
					onClick={this.openMobileCart}
				>
					<div
						className={
							items.length > 0
								? `Cart__mobileCart__container--hasItems`
								: `Cart__mobileCart__container`
						}
					>
						<div className="Cart__mobileCart__container__info">
							{pixText ? null : (
								<>
									<ImageIcon className="Cart__mobileCart__container__info__icon" />
									<Text>{totalOrderPhotos}</Text>
								</>
							)}
						</div>
						<div
							className={
								!pixText
									? "Cart__mobileCart__container__divisor"
									: "Cart__mobileCart__container__divisor__hidden"
							}
						/>
						<div className="Cart__mobileCart__container__info">
							<>
								<ShoppingCartIcon className="Cart__mobileCart__container__info__icon" />
								<Text>{`R$ ${order.total || 0.0}`}</Text>
							</>
						</div>
					</div>
				</div>
				<div
					className={`Cart__desktopCart ${
						mobileCartIsOpen ? "Cart__desktopCart--opened" : ""
					}`}
				>
					{mobileCartIsOpen && (
						<div className="Cart__desktopCart__close">
							<CloseIcon
								className="Cart__desktopCart__close__icon"
								onClick={this.closeMobileCart}
							/>
						</div>
					)}
					<Box className={`Cart__desktopCart__wrapper ${allDigitalIsSelected ? "Cart__desktopCart__disabled": ""}`} borderPosition="none">
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className="Cart__desktopCart__container"
						>
							{orderMedias.length > 0 ? (
								<div className="Cart__desktopCart__list">
									{orderMedias.map((item) => (
										<CartItem
											selected={
												(item.photo && item.photo.id === selectedMediaId) ||
												(item.video && item.video.id === selectedMediaId)
											}
											itemMedia={item.photo || item.video}
											key={item.id}
											onSelect={() =>
												changeSelectedOrderMedia(
													item.video ? item.video.id : item.photo.id
												)
											}
											onRemove={() => this.removePhoto(item.id)}
											onLoading={mediaBeingRemoved.includes(item.id)}
										/>
									))}
								</div>
							) : (
								<div className="Cart__desktopCart__emptyList">
									<PhotoLibraryIcon className="Cart__desktopCart__background" />
									<Text className="Cart__desktopCart__wrapper__emptyMessage">
										{formatMessage(messages.choosePics)}
									</Text>
								</div>
							)}
						</Grid>
					</Box>
					<Box className="Cart__desktopCart__checkout" borderPosition="none">
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							xl={12}
							className="Cart__desktopCart__actions"
						>
							{all_digital && (
								<div className="Cart__desktopCart__checkout__allDigital" >
									{(this.showTooltip()) && (
										<Tooltip className="Cart__desktopCart__checkout__allDigital__tooltip" onClick={this.handleTootilpClose} text={formatMessage(messages.tooltipCartText)} hasBeenVisible={this.state.hasBeenVisible}/>
									)}
									<AllDigitalPackage
										productName={all_digital.description}
										productPrice={all_digital.price}
										isAdded={Order.hasAllDigital(order)}
										onChange={this.handleAllDigitalChange}
										isAdding={isAddingAllDigital}
								/>
								</div>
							)}
							<CartPrice
								totalPhoto={totalOrderPhotos}
								totalPrice={order.total}
							/>
							<Button
								className=""
								color="secondary"
								loading={false}
								onClick={this.openBuyModal}
								size="medium"
								disabled={totalOrderPhotos === 0}
							>
								{pixText ? (
										<Text>{formatMessage(messages.checkingPayment)}</Text>
									) : (
										<Text>{formatMessage(messages.checkoutButton)}</Text>
								)}
							</Button>
						</Grid>
					</Box>
				</div>
				<BuyModal
					isOpened={this.state.buyModalOpened}
					onClose={this.onCloseBuyModal}
					itemsQuantity={this.state.items}
				></BuyModal>
				{(method !== 'creditCard') && (hasPixPayment || pixText) && !loading ? (
					<Timer
						text={formatMessage(messages.checkingPayment)}
						onFinished={this.onGetPayment}
					/>
				) : null}
			</div>
		);
	}
}

Cart.propTypes = {
	intl: PropTypes.object.isRequired,
	cardCode: PropTypes.string.isRequired,
	products: PropTypes.shape({
		loading: PropTypes.bool,
	}),
	order: PropTypes.shape({
		loading: PropTypes.bool,
	}),
	fetchProducts: PropTypes.func,
	fetchOrder: PropTypes.func,
};

const mapStateToProps = (state) => ({
	products: state.products,
	order: state.order,
	media: state.media,
	organization: state.organization,
	payment: state.payment,
});

const mapActionsToProps = (dispatch) => ({
	fetchProducts: (orgId) => dispatch(fetchProducts(orgId)),
	fetchOrder: (cards, orgId) => dispatch(fetchOrder(cards, orgId)),
	removeMediaFromOrder: (orderId, orderItemId, associatedItemId) =>
		dispatch(removeMediaFromOrder(orderId, orderItemId, associatedItemId)),
	addAllDigital: (orderId, productId) =>
		dispatch(addAllDigital(orderId, productId)),
	removeAllDigital: (orderId, productId) =>
		dispatch(removeAllDigital(orderId, productId)),
	changeSelectedOrderMedia: (photoId) =>
		dispatch(changeSelectedOrderMedia(photoId)),
	getPayment: (orderId) => dispatch(getPayment(orderId)),
	finishOrder: (orderId) => dispatch(finishOrder(orderId)),
	clearPayment: () => dispatch(clearPayment()),
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(Cart));
